import React from 'react';
import { Link } from 'react-scroll';
import WorkExperience from './WorkExperience';
import Projects from './ProjectsResearch';
import { HomeContainer, IntroContainer, NavLinksContainer, NavLinks, Section, LinksContainer, ContactLink } from './Styles';



function Home() {
  return (
    <div>
      <HomeContainer>
        <IntroContainer>
          <h1>Ali Hamza</h1>
          <p>Welcome to my portfolio. Explore my work in backend development, AI, and more.</p>
          <div>
            <p>
              Feel free to reach out to me at{' '}
              <ContactLink href="mailto:alihamza19999@gmail.com">alihamza19999@gmail.com</ContactLink>
            </p>
            <LinksContainer>
              <ContactLink href="https://github.com/hurryingauto3" target="_blank" rel="noopener noreferrer">
                GitHub
              </ContactLink>
              <ContactLink href="https://www.linkedin.com/in/alihamza99/" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </ContactLink>
              <ContactLink href="https://x.com/HurryingAuto3" target="_blank" rel="noopener noreferrer">
                Twitter
              </ContactLink>
            </LinksContainer>
          </div>
        </IntroContainer>
        <NavLinksContainer>
          <NavLinks>
            <Link to="work" smooth={true} duration={500}>
              Work
            </Link>
            <Link to="research" smooth={true} duration={500}>
              Projects
            </Link>
          </NavLinks>
        </NavLinksContainer>
      </HomeContainer>

      <Section id="work" style={{ height: '90vh' }}>
        <h1>Work Experience</h1>
        <WorkExperience />
      </Section>

      <Section id="research" style={{ height: '90vh' }}>
        <h1>Projects & Research</h1>
        {/* <Projects /> */}
      </Section>
    </div>
  );
}

export default Home;
