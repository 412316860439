import styled from 'styled-components';

const HomeContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px 100px; /* Increased left and right padding */
  text-align: left;
  background-color: #f9f9f9;
  height: 90vh;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 50px 20px;
  }
  
`;

const IntroContainer = styled.div`
  flex: 1;
  max-width: 50%;
  & > h1 {
    font-size: 80px; /* Increased font size */
    margin-bottom: 20px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    background-color: #f9f9f9;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }

  & > p {
    font-size: 60px; /* Increased font size */
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;

    & > p {
      font-size: 20px; /* Adjusted font size for mobile */
    }

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        }

  }

    & > div {
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    }

`;

const NavLinksContainer = styled.div`
  flex: 1;
  max-width: 40%;
  flex-direction: column;
  display: flex;
  justify-content: left;

  @media (max-width: 768px) {
    max-width: 100%;
    justify-content: center;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: flex-start;

  & > a {
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 60px; /* Adjusted font size for links */
    font-family: 'Roboto', sans-serif;
    color: #000;
    background-color: #f9f9f9;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #e0e0e0;
    }
  }
  @media (max-width: 768px) {
    height: auto;

    & > a {
      font-size: 40px; /* Adjusted font size for mobile */
    }
  }
`;

const Section = styled.div`
  padding: 100px 50px;
  text-align: left;
  background-color: #f9f9f9;

  &:nth-child(even) {
    background-color: #fff;
  }
`;

const WorkContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
  max-width: 100%;
  
  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
`;

const SectionContainer = styled.div`
  flex: 0 0 80%;
  max-width: 100%;
  height: 100%;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  padding: 20px;
  background-color: #fff;
  scroll-behavior: smooth;
  border-radius: 10px;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const TextContainer = styled.div`
  width: 100%;
`;

const SectionHeader = styled.div`
  background: #fff;
  padding: 10px 0;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 500px;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 20px;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  overflow-x: auto;

  img {
    height: 500px; /* Set the height of the images */
    width: auto;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  gap: 20px;

`;

const ContactLink = styled.a`
  color: #004AAC;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;

  &:hover {
    color: #E37839;
  }
`;

const TechnologiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
`;


export {
    HomeContainer,
    IntroContainer,
    TechnologiesContainer,
    NavLinksContainer,
    NavLinks,
    Section,
    WorkContainer,
    SectionContainer,
    TextContainer,
    SectionHeader,
    IframeContainer,
    Iframe,
    ImageContainer,
    LinksContainer,
    ContactLink
};